<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row py-3 px-4" id="filter-card">
        <div class="col-lg-12 col-xl-12 px-0">
          <div class="row justify-content-start items-baseline w-100 mb-2">
        <span
            @click="$router.go(-1)"
            class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2">
          <i class="fa fa-chevron-left"></i>
        </span>
            <h3 class="d-inline-block" id="page-title">{{ $t('general.user').toUpperCase() }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <dashboard-box :back="true" class="px-5 py-4">
        <template v-slot:preview>
          <div class="row w-100">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <text-input
                  :title="`${$t('teams.name')}`"
                  :model.sync="itemProxy.name"
                  :is-valid="itemProxy.name != null && itemProxy.name.length !== 0 ? true : null"
              ></text-input>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <custom-multi-select
                  :model.sync="itemProxy.user_id"
                  :options="users"
                  :title="$t('teams.responsible_person')"
                  :itemPerRow="1"
                  name="responsiblePerson"
                  :max="1"
                  :is-inline="true"
                  :not-list="true"
              />
            </div>

            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <custom-multi-select
                  :model.sync="itemProxy.team_members"
                  :options="users"
                  :title="$t('teams.members')"
                  :itemPerRow="1"
                  name="members"
                  :is-inline="true"
              />
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <button-with-icon
                class="mr-3 danger-border-1px"
                @onClick="$router.go(-1)"
                :text="$t('general.cancel')"
                size="lg"
                :icon-name="null"
            ></button-with-icon>
            <button-with-icon
                @onClick="createOrUpdate"
                :text="$t(id != null ? 'general.save' : 'general.create')"
                size="lg"
                :icon-name="null"
                :disabled="!isValidToSubmit"
            ></button-with-icon>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

import baseModule, {
  BASE_URL,
  ERROR,
  LOADING,
  MODULE_NAME,
  SUCCESS,
  ITEM,
  SET_ITEM,
  GET_ITEM,
  UPDATE_ITEM,
  CREATE_ITEM
} from "@/core/services/store/team/team.module";

import userModule, {
  BASE_URL as BASE_URL_USER,
  MODULE_NAME as MODULE_NAME_USER,
  GET_ITEMS as GET_ITEMS_USER,
  ITEMS as ITEMS_USER,
} from "@/core/services/store/user/user.module";


import TextInput from "@/assets/components/inputs/TextInput";
import FileInput from "@/assets/components/inputs/FileInput";
import FileInputChange from "@/assets/components/inputs/FileInputChange";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import store from "@/core/services/store";
import router from "@/router";

const _MODULE_NAME = MODULE_NAME;
const _MODULE_NAME_USER = MODULE_NAME_USER;

export default {
  name: "UserCreateEdit",
  components: {
    ButtonWithIcon,
    TextInput,
    DashboardBox,
    FileInput,
    FileInputChange,
    SelectInput,
    CustomMultiSelect
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(_MODULE_NAME, baseModule)
    registerStoreModule(_MODULE_NAME_USER, userModule)
  },
  data() {
    return {
      id: this.$route.params.id,
      indexRouteName: 'management.teams.index',
      itemProxy: {
        id: -1,
        name: null,
        user_id: "",
        team_members: []
      },
    };
  },
  computed: {
    error() {
      return store.getters[_MODULE_NAME + '/' + ERROR];
    },
    loading() {
      return store.getters[_MODULE_NAME + '/' + LOADING];
    },
    success() {
      return store.getters[_MODULE_NAME + '/' + SUCCESS];
    },
    item() {
      return store.getters[_MODULE_NAME + '/' + ITEM];
    },
    usersRaw() {
      return store.getters[_MODULE_NAME_USER + '/' + ITEMS_USER];
    },
    users() {
      let items = store.getters[_MODULE_NAME_USER + '/' + ITEMS_USER];
      if (items) {
        return this.convertArrayToObjectByKey(items, 'id', 'name');
      }
      return {};
    },
    isValidToSubmit(){
      if(this.itemProxy.name === null || this.itemProxy.name === '') return false;
      if(this.itemProxy.user_id === null || this.itemProxy.user_id === '') return false;
      if(this.itemProxy.team_members === null || this.itemProxy.team_members.length === 0) return false;

      return true;
    },
  },
  methods: {
    setUserItem() {
      let teamItem = this.item;
      if (teamItem) {
        let teamMembers = teamItem.team_members;
        teamItem.team_members = [];

        if (teamMembers && teamMembers.length) {
          teamItem.team_members = teamMembers.map(teamSubItem => {
            if (teamSubItem.hasOwnProperty('user_id')) {
              return teamSubItem.user_id.toString();
            }

            return teamSubItem.toString();
          });
        }

        if (teamItem.user) {
          teamItem.user_id = teamItem.user.id;
        }

        this.itemProxy = teamItem;
      }
    },
    onProfileImageChange(payload) {
      this.itemProxy.avatar_file = payload.blob;
    },
    createOrUpdate() {
      let formData = {
        name:  this.itemProxy.name,
        team_members:  this.itemProxy.team_members,
        user_id:  this.itemProxy.user_id.toString(),
      }

      if (this.id) {
        let payload = {
          url: BASE_URL + '/' + this.id,
          id: this.id,
          contents: formData
        }
        this.$store.dispatch( _MODULE_NAME + '/'+ UPDATE_ITEM, payload);
      } else {
        let payload = {
          url: BASE_URL,
          id: this.id,
          contents: formData
        }
        this.$store.dispatch( _MODULE_NAME + '/'+ CREATE_ITEM, payload);
      }
    },
    getUsers() {
      this.$store.dispatch(_MODULE_NAME_USER + '/' + GET_ITEMS_USER, {
        url: BASE_URL_USER,
        filters: {}
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("general.user"), route: this.indexRouteName},
      {title: this.$t(this.id ? "general.user_edit" : "general.user_create")},
    ]);



    this.getUsers();


    if(!this.id){
      this.$store.commit(_MODULE_NAME + '/'+ SET_ITEM, this.tempItem);
    } else {
      let payload = {
        url: BASE_URL + '/' +this.id,
        id: this.id,
      }
      this.$store.dispatch(_MODULE_NAME + '/'+ GET_ITEM, payload)
    }
  },
  watch: {
    item(value) {
      if (value) {
        this.setUserItem();
      }
    },
    users() {
      this.setUserItem();
    },
    usersRaw() {
      this.setUserItem();
    },
    success(value) {
      if (value) {
        let message = this.id == null ? this.$t('general.successfully_created') : this.$t('general.successfully_updated');
        let self = this;
        setTimeout(() => {
          self.sweetAlertSuccess(message).then((result) => {
            if (result) {
              router.push({name: this.indexRouteName});
            }
          })
        }, 500);
      }
    }
  }
}
</script>

<style scoped>
#dashboard-box {
  padding: 10px;
}
</style>
